<template>
  <div id="about-page" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
    <!-- Start Page Title Area -->
		<div class="page-title-area page-title-area-padding bg-1">
			<div class="container">
				<div class="page-title-content">
					<h2>Billion Acts of Peace</h2>
				</div>
			</div>
		</div>

   <!-- Start About Area -->
    <section id="about" class="about-section ptb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-area-content">
              <span>Join the Global Call to Action</span>
              <h3>14 Nobel Laureates + 1.3 Million Youth + YOU</h3>
              <p>Can 1 Billion Acts of Peace Change the World?</p>
            </div>

            <p class="mt-3 white-space-pre-wrap">
              We believe it can.  The Billion Acts Global Call to Action began with 10
              Nobel Peace Laureates and 3000 PeaceJam youth from over 40
              countries creating a new theory of change.
              <br><br>
              What if we collectively and intentionally focused on 10 Issue Areas identified
              as the most important for bringing peace, and true human security,
              and asked everyday people to take action in their community to create
              a tipping point for change?  What if instead of waiting for our leaders
              to step up, we worked together to create 1 Billion Acts of Peace by 2022,
              and brought about the changes ourselves?
              <br><br>
              <img src="../assets/img/ba-area.jpg" alt="">
            </p>

            <a href="https://www.billionacts.org/" target="_blank" class="post-button mt-4 d-block" rel="noreferrer">
              <i class="flaticon-curve-arrow"></i>
              Be a Part of the Billion Acts Movement
            </a>
          </div>

          <div class="col-lg-6">
            <div class="about-image">
              <img class="default-img" src="../assets/img/bapage-image.jpg" alt="ba-page">

              <div class="image-content">
                <p>Join the Global Call to Action led by 14 Nobel Laureates and Youth Worldwide</p>
                <div class="dot"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "BillionActs",
    async mounted() {
      window.scrollTo(0, 0);
    },
  }
</script>

<style scoped lang="scss">
  .page-title-area-padding {
    padding-top: 90px;
    padding-bottom: 75px;
    background: linear-gradient(45deg, #786CF0, #8e84f6);
  }

  #our-story {
    padding-bottom: 350px;
  }

  .video-section {
    background-color: #effafc;

    .video-frame {
      position: relative;
      width: 100%;
      margin-top: -300px;
      cursor: pointer;

      &:hover .play-btn {
        transform: translate(-50%, -50%) scale(1.1);
      }

      img {
        width: 100%;
      }

      .play-btn {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border: 3px solid #fff;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.45098039215686275);
        cursor: pointer;
        transition: 0.5s;

        img {
          width: 50px;
        }
      }
    }
  }
  .others-section {
    background-color: #0c5460;
    overflow: hidden;

    .others-one-btn, .others-two-btn {
      position: initial;
      display: flex;
      justify-content: center;
    }

    a {
      display: block;
      border: none;
      text-align: center;
      position: relative;
      z-index: 2;
      font-size: 22px;
      color: #ffffff;
      margin-bottom: 0;
      text-transform: initial;

      img {
        margin-right: 15px;
      }
    }

    &:before, &:after {
      content: none;
    }

    .background {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -30px;
        bottom: -30px;
        right: 0;
        left: 0;
        z-index: 1;
      }

      &.one:before {
        background-color: #31bfd7;
        left: -100000px;
      }

      &.two:before {
        background: linear-gradient(90deg, #31bfd7, #0fdbaf);
        left: 0;
      }

      &.three:before {
        background-color: #10dbaf;
        right: -10000px;
      }
    }
  }


  .video-url-modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.71);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    .close-modal {
      position: fixed;
      z-index: 99999;
      top: 10px;
      right: 10px;
      color: #000;
      font-size: 25px;
      background: #ffffffde;
      width: 40px;
      border-radius: 50%;
      height: 40px;
      transform: rotate(45deg);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .about-image {
    img {
      width: 100%;
      height: 567px;
      object-fit: cover;
    }
  }

  @media (max-width: 992px) {
    #our-mission .row {
      flex-direction: column-reverse;
    }

    .default-img {
      margin-top: 25px;
      height: 250px !important;
    }

    .video-section .video-frame {
      margin-top: 0;
    }

    #our-story {
      padding-bottom: 50px;
    }

    .others-section {
      background: linear-gradient( #31bfd7, #0fdbaf);
    }

    .others-section a {
      font-size: 18px;
      img {
        width: 34px;
      }
    }
    .others-section .background:before {
      content: none;
    }
  }
  @media only screen and (max-width: 767px) {
    .others-two-content {
      margin-bottom: 30px;
      text-align: center;
    }
    .others-section a {
      min-width: 200px;
      text-align: left;
    }
  }
</style>
